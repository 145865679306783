import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  Button,
  Box,
  Image,
  Circle,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import moment from "moment";
import { style } from "../../../../../global/style";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { showDownloadBtn, showImageOrPdf } from "../../../../../global/general";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";
import EmptyReport from "../NotReport/EmptyReport";
import { Link, useRouteMatch } from "react-router-dom";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../../../../global/Animations/Animations";

const ViewReport = ({ medicalReport, Consultation }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  const { colorMode } = useColorMode();
  const reportRef = useRef();
  const { url } = useRouteMatch();
  const onModalClose = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (reportRef && medicalReport) {
      reportRef.current.innerHTML = "";
      reportRef.current.insertAdjacentHTML("beforeend", medicalReport.note);
    }
  }, [reportRef, medicalReport]);

  const renderView = () => {
    if (medicalReport) {
      return (
        <motion.div
          className="my-3"
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Box className="d-flex justify-content-between align-items-center">
            <Text
              fontSize="2xl"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
            >
              Doctor Report
            </Text>
            <Button
              as={Link}
              to={`${url}/add-report`}
              bg={checkColorMode(colorMode)}
              color={style.white}
              size="lg"
              _hover={{
                bg: checkColorModeHover(colorMode),
                color: "white",
              }}
              disabled={
                Consultation.status === "done" ||
                Consultation.status === "finished_appointment" ||
                Consultation.status === "new"
              }
              pointerEvents={
                Consultation.status === "done" ||
                Consultation.status === "finished_appointment" ||
                Consultation.status === "new"
                  ? "none"
                  : ""
              }
            >
              <span className="me-2">
                <i className="fas fa-plus"></i>
              </span>{" "}
              Add
            </Button>
          </Box>

          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-md-12 p-3 rounded row m-0 my-3 align-items-center"
          >
            <Box className="row m-0 align-items-center">
              <Box className="col-md-6">
                <Text fontSize="xl" color={checkColorMode(colorMode)}>
                  {medicalReport.title}
                </Text>
                <Text fontSize="xs" color={style.smallTextGray}>
                  {moment
                    .unix(medicalReport.createdAt)
                    .format("DD/MM/YYYY hh:mm A")}
                </Text>
              </Box>
              <Box className="col-md-6 row m-0 justify-content-end">
                {showDownloadBtn(medicalReport)}

                {/* <ReactToPrint content={() => ref.current}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        className="rounded m-2"
                        bg={style.secondary}
                        color="#fff"
                        fontSize="14px"
                        _hover={{ bg: style.secondary_hover }}
                        minW="140px"
                        onClick={handlePrint}
                      >
                        <span className="me-4">
                          <i className="fas fa-print"></i>
                        </span>{" "}
                        Print
                      </Button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint> */}
                {showDownloadBtn(medicalReport, true)}
              </Box>
            </Box>

            <Box className="row m-0 align-items-center" ref={ref}>
              <Box>
                <Box
                  bg={
                    colorMode === "dark"
                      ? style.smallTextGrayLight
                      : style.bg_Light_Gray
                  }
                  border={`1px solid ${style.smallTextGrayLightBorder}`}
                  boxShadow="xs"
                  className="col-12 rounded p-3 mt-3"
                  ref={reportRef}
                >
                  {/*  */}
                </Box>
                <Box className="p-y mt-3">
                  {showImageOrPdf(medicalReport, onModalClose)}
                </Box>
              </Box>
            </Box>
          </Box>

          <Modal
            onClose={onModalClose}
            isOpen={isOpen}
            isCentered
            autoFocus={false}
            size="4xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalBody
                style={{
                  overflow: "auto",
                  maxHeight: "100vh",
                }}
              >
                <Box
                  className="d-flex justify-content-center flex-wrap"
                  marginTop={"20px"}
                >
                  <Box
                    className="d-flex justify-content-end w-100"
                    marginBottom={"20px"}
                  >
                    <Button onClick={onModalClose}>
                      <svg
                        viewBox="0 0 24 24"
                        focusable="false"
                        class="chakra-icon css-onkibi"
                        aria-hidden="true"
                      >
                        <path
                          fill="currentColor"
                          d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
                        ></path>
                      </svg>
                    </Button>
                  </Box>
                  <Box>
                    <Image
                      src={medicalReport.fileLocation}
                      alt={medicalReport.fileName}
                      className=""
                    />
                  </Box>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </motion.div>
      );
    } else {
      return <EmptyReport Consultation={Consultation} />;
    }
  };
  return renderView();
};

export default ViewReport;
