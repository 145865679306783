import React from "react";
import { Input, Button, Text, Box, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { style } from "../../../../../../global/style";

import "./repeated.scss";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../../global/changeColor";
function HeaderSection({
  headerName,
  onInputChange,
  buttonLink,
  question,
  SingleConsultation,
}) {
  const { colorMode } = useColorMode();
  return (
    <div className="h4 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
      <Text
        color={checkColorMode(colorMode)}
        fontWeight="semibold"
        className="my-2 me-3"
      >
        {headerName}{" "}
      </Text>
      <div className="d-flex flex-wrap align-items-center my-2">
        <Box
          className="me-3 input-search my-2"
          color={checkColorMode(colorMode)}
        >
          <Input
            // w="300px"
            fontSize="15px"
            placeholder="Search"
            size="lg"
            className="ps-5"
            onChange={onInputChange}
            bg={checkBgColorMode(colorMode)}
          />
          <span className="icon-search">
            <i className="fas fa-search"></i>
          </span>
        </Box>
        <div className="">
          <Button
            className="rounded text-white my-2"
            bg={checkColorMode(colorMode)}
            size="lg"
            _hover={{ bg: checkColorModeHover(colorMode) }}
            _active={{ bg: checkColorMode(colorMode) }}
            as={Link}
            to={buttonLink}
            fontSize="17px"
            disabled={
              SingleConsultation.status === "done" ||
              // SingleConsultation.status === "finished_appointment" ||
              SingleConsultation.status === "new"
            }
            pointerEvents={
              SingleConsultation.status === "done" ||
              // SingleConsultation.status === "finished_appointment" ||
              SingleConsultation.status === "new"
                ? "none"
                : ""
            }
          >
            <span className="me-2">
              <i className="fas fa-plus"></i>
            </span>{" "}
            {question ? "Ask" : "Request"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
