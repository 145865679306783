import { Box, Button, Image, Text } from "@chakra-ui/react";
import { style } from "./style";
import PDF from "../../assets/svg-icons/pdf.svg";
export const showImageOrPdf = (file, onModalClose) => {
  const fileName = file.fileName;
  const generatedFile = file.generatedFile;
  // const fileLocation = file.fileLocation;
  if (fileName || generatedFile) {
    const startIndex = fileName
      ? fileName.lastIndexOf(".", fileName.length - 1) + 1
      : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
    const checkExt = fileName
      ? fileName.substring(startIndex)
      : generatedFile.substring(startIndex);
    if (checkExt === "pdf") {
      if (file.generatedFile || file.fileLocation) {
        return (
          <Box
            justifyContent="center"
            alignItems="center"
            p="5px"
            display="flex"
            flexWrap="wrap"
            boxSize="170px"
            border={`1px solid ${style.smallTextGrayLightBorder}`}
            className="rounded"
            position="relative"
          >
            <Box
              w="100%"
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <Image boxSize="130px" src={PDF} alt={"PDF"} />
            </Box>
            {file.fileName ? (
              <Text
                fontSize="14px"
                fontWeight="semibold"
                color={style.secondary}
              >
                {file.fileName.substring(0, 15) + "..." || "null"}
              </Text>
            ) : null}
            <Box
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              left="0"
              bg="gray.100"
              opacity=".5"
              transition=".5s"
              _hover={{ opacity: ".8" }}
            >
              <a
                href={file.fileLocation || file.generatedFile}
                target="_blank"
                className="nav-link w-100 h-100 d-flex align-items-center justify-content-center"
              >
                <Text
                  fontWeight="bold"
                  fontSize="2xl"
                  className="text-center"
                  color="primary"
                >
                  View <br />
                  <span>
                    <i className="fas fa-eye"></i>
                  </span>
                </Text>
              </a>
            </Box>
          </Box>
        );
      }
    } else if (checkExt === "png" || checkExt === "jpg") {
      return (
        <Box
          alignItems="center"
          p="5px"
          display="flex"
          flexWrap="wrap"
          className="rounded"
          position="relative"
          boxSize="170px"
        >
          <Image
            src={file.fileLocation}
            alt={file.fileName}
            border={`1px solid ${style.smallTextGrayLightBorder}`}
            className=""
            h={"100%"}
            w={"100%"}
          />
          <Box
            boxSize="170px"
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            left="0"
            bg="gray.100"
            opacity=".5"
            transition=".5s"
            _hover={{ opacity: ".8" }}
            onClick={onModalClose}
          >
            <Text
              fontWeight="bold"
              fontSize="2xl"
              className="d-flex justify-content-center align-items-center "
              color="primary"
              h={"100%"}
              cursor={"pointer"}
              onClick={() => {
                console.log("text");
              }}
            >
              View <br />
              <span>
                <i className="fas fa-eye"></i>
              </span>
            </Text>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  }
};

export const showDownloadBtn = (file, fromPrint) => {
  const fileName = file.fileName;
  const generatedFile = file.generatedFile;
  ////////

  ///////////

  if (fileName || generatedFile) {
    /////
    const startIndex = fileName
      ? fileName.lastIndexOf(".", fileName.length - 1) + 1
      : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
    const checkExt = fileName
      ? fileName.substring(startIndex)
      : generatedFile.substring(startIndex);
    ////////
    if (checkExt === "pdf") {
      return (
        <Button
          className="rounded m-2 text-white"
          bg={fromPrint ? style.secondary : style.primary}
          color="#fff"
          fontSize="14px"
          _hover={{
            bg: fromPrint ? style.secondary_hover : style.primary_hover,
          }}
          minW="140px"
        >
          <a
            href={file.fileLocation || file.generatedFile}
            target="_blank"
            className="text-white"
          >
            {fromPrint ? (
              <span className="me-4">
                <i className="fas fa-print"></i>
              </span>
            ) : (
              <span className="me-3">
                <i className="fas fa-download"></i>
              </span>
            )}{" "}
            {fromPrint ? "Print" : "Download"}
          </a>
        </Button>
      );
    }
  }
};
